import { Component, signal } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Sequence } from '@app/models/sequence.interface';
import { SequenceHelperService } from '@app/services/helpers/sequence-helper.service';
import { Observable, tap } from 'rxjs';
import { HeadMetaComponent } from "@app/components/head-meta/head-meta.component";
import { LoadingErrorBlockComponent } from "@app/components/loading-error-block/loading-error-block.component";
import { LetDirective } from '@ngrx/component';
import { CreatedUpdatedDisplayComponent } from "@app/components/created-updated-display/created-updated-display.component";
import { DetailDisplayComponent } from "@app/components/detail-display/detail-display.component";
import { faCheck, faPenToSquare, faXmark, faArrowLeft, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthService } from '@app/services/auth.service';
import { BaseChartDirective } from 'ng2-charts';
import { ChartData } from 'chart.js';
import { ProcessedSequenceItem } from '@app/models/processed-sequence-item.interface';
import { RawSequenceItem } from '@app/models/raw-sequence-item.interface';
import { PeakEvent } from '@app/models/peak-event.interface';
import { TamperEvent } from '@app/models/tamper-event.interface';
import { SequenceEvent } from '@app/models/sequence-event.interface';
import { SequenceExportType } from '@app/enums/SequenceExportType';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DateRangeComponent } from '@app/components/date-range/date-range.component';
import { DateRange } from '@app/interfaces/date-range.interface';
@Component({
  selector: 'app-sequence-detail',
  standalone: true,
  templateUrl: './detail.page.html',
  styleUrl: './detail.page.scss',
  imports: [
    HeadMetaComponent,
    LoadingErrorBlockComponent,
    LetDirective,
    CreatedUpdatedDisplayComponent,
    DetailDisplayComponent,
    FontAwesomeModule,
    RouterModule,
    DatePipe,
    BaseChartDirective,
    ReactiveFormsModule,
    DateRangeComponent,
  ]
})
export class SequenceDetailPage {
  title = "Sequence Detail";
  id: number;
  searchForm: FormGroup = new FormGroup({
    date_range: new FormControl<number>(1),
    start: new FormControl<Date>(new Date(new Date().getTime() - (1 * 24 * 60 * 60 * 1000))),
    end: new FormControl<Date>(new Date()),
  });
  dateRanges:DateRange = {
    start: new Date(new Date().getTime() - (1 * 24 * 60 * 60 * 1000)),
    end: new Date,
  };
  formValid = signal(false);
  formValue = signal<DateRange | null>(null);
  sequence$: Observable<Sequence>;

  faCheck = faCheck;
  faXmark = faXmark;
  faPenToSquare = faPenToSquare;
  faArrowLeft = faArrowLeft;
  faFileCsv = faFileCsv;

  sequenceExportType: typeof SequenceExportType = SequenceExportType

  public chartData:ChartData = {
    datasets: []
  };

  constructor(
    private route: ActivatedRoute,
    private sequenceHelper: SequenceHelperService,
    public auth: AuthService,
  ) {
    this.id = this.route.snapshot.params['id'];
    this.sequence$ = this.sequenceHelper.getByIdFiltered(this.id, new Date(new Date().getTime() - (1 * 24 * 60 * 60 * 1000)), new Date())
      .pipe(tap((sequence) => this.initGraphs(sequence)));
  }

  public submit(){
    const form = this.searchForm.value;
    if(form) {
      switch(parseInt(form.date_range)){
        case 1: // Past 24 hours
          form.start = new Date(new Date().getTime() - (1 * 24 * 60 * 60 * 1000));
          form.end = new Date();
        break;
        case 2: // Past 2 Days
          form.start = new Date(new Date().getTime() - (2 * 24 * 60 * 60 * 1000));
          form.end = new Date();
        break;
        case 3: // Past 7 Days
          form.start = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
          form.end = new Date();
        break;
        case 4: // Past 30 Days
          form.start = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
          form.end = new Date();
        break;
        case 5:
          // values set by user
          let val = this.formValue();
          if(val){
            form.start = val.start;
            form.end = val.end;
          }
        break;
        case 0: // All
        default:
          form.start = null;
          form.end = null;
        break;
      }
      this.dateRanges = {
        start: form.start,
        end: form.end,
      };
      this.sequence$ = this.sequenceHelper.getByIdFiltered(this.id, form.start == null ? null : new Date(form.start), form.end == null ? null : new Date(form.end))
        .pipe(tap((sequence) => this.initGraphs(sequence)));
    }
  }

  public exportToCsv(export_type:SequenceExportType){
    this.sequence$.subscribe(sequence => {
      switch(export_type){
        case SequenceExportType.RawSequenceItem:
          if(sequence.raw_sequence_items){
            this.RawSequenceItemsToCsv(sequence.raw_sequence_items)
          }
        break;
        case SequenceExportType.ProcessedSequenceItem:
          if(sequence.processed_sequence_items){
            this.ProcessedSequenceItemsToCsv(sequence.processed_sequence_items)
          }
        break;
        case SequenceExportType.PeakEvent:
          if(sequence.peak_events){
            this.PeakEventsToCsv(sequence.peak_events)
          }
        break;
        case SequenceExportType.TamperEvent:
          if(sequence.tamper_events){
            this.TamperEventsToCsv(sequence.tamper_events)
          }
        break;
        case SequenceExportType.SequenceEvent:
          if(sequence.sequence_events){
            this.SequenceEventsToCsv(sequence.sequence_events)
          }
        break;
      }
    });
  }

  private ProcessedSequenceItemsToCsv(items: ProcessedSequenceItem[]):string{
    return this.jsonToCsv(items, [
      'flash_timestamp',
      'readable_timestamp',
      'set_to_zero_threshold',
      'smoothed_temp_1',
      'smoothed_humidity_1',
      'ir_sensor_1',
      'ir_sensor_2',
      'ambient_sensor_1',
      'ambient_sensor_2',
      'is_tamper',
      'battery_level',
    ]);
  }

  private RawSequenceItemsToCsv(items: RawSequenceItem[]):string{
    return this.jsonToCsv(items, [
      'activity_at',
      'time_of_reading',
      'readable_timestamp',
      'flash_timestamp',
      'delivered_at',
      'vapor_concentration',
      'temperature_1',
      'temperature_2',
      'relative_humidity_1',
      'relative_humidity_2',
      'ambient_raw_1',
      'ambient_raw_2',
      'bio_raw_1',
      'bio_raw_2',
      'is_tamper',
      'battery_level',
    ]);
  }

  private PeakEventsToCsv(items: PeakEvent[]):string{
    return this.jsonToCsv(items, [
      'event_id',
      'event_number',
      'started_at',
      'ended_at',
      'peak_tac_at',
      'peak_tac_value',
      'peak_prominence_value',
      'duration_minutes',
      'hour_threshold_duration',
      'half_hour_threshold_duration',
      'event_rise_slope',
      'event_fall_slope',
      'area_under_curve',
    ]);
  }

  private TamperEventsToCsv(items: TamperEvent[]):string{
    return this.jsonToCsv(items, [
      'event_id',
      'sensor_type_id',
      'event_number',
      'started_at',
      'ended_at',
      'baseline_data_value',
      'average_delta_change',
      'duration_minutes',
      'out_of_range',
      'standard_dev',
      'battery_decay_slope',
      'battery_percentage',
    ]);
  }

  private SequenceEventsToCsv(items: SequenceEvent[]):string{
    return this.jsonToCsv(items, [
      'event_id',
      'created_at',
    ]);
  }

  private jsonToCsv(items: any, headers: string[]){
    var replacer = function(key:any, value:any) { return value === null ? '' : value } 
    var csv = items.map(function(row:any){
      return headers.map(function(fieldName){
        return JSON.stringify(row[fieldName], replacer)
      }).join(',')
    });
    csv.unshift(headers.join(','));
    csv = csv.join('\r\n');
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
    return csv;
  }

  private initGraphs(sequence: Sequence){
    if(!sequence.processed_sequence_items){
      return
    }

    let activity_at:Array<string> = [];
    // Always plotted by default for all users:
    let set_to_zero_threshold:Array<number> = [];
    let smoothed_temp_1:Array<number> = [];
    let smoothed_humidity_1:Array<number> = [];
    let ir_sensor_1:Array<number> = [];
    let ir_sensor_2:Array<number> = [];
    // Available by checkbox (by default not plotted)
    let battery_level:Array<number> = [];
    let is_tamper:Array<number> = [];
    let ambient_sensor_1:Array<number> = [];
    let ambient_sensor_2:Array<number> = [];
    // The following are useful for research/analysis and only checked if something seems off.
    let smoothed_voltage:Array<number> = [];
    let smoothed_temp_2:Array<number> = [];
    let smoothed_humidity_2:Array<number> = [];
    let final_graphene_sensor_data:Array<number> = [];
    let baseline_graphene_sensor_data:Array<number> = [];
    let tac_value:Array<number> = [];

    if(sequence.processed_sequence_items.length > 0) {
      activity_at = sequence.processed_sequence_items.map<string>(o => (o.activity_at ? formatDate(o.activity_at, 'short', 'en-US') : '')).filter(n => n);
      // Always plotted by default for all users:
      set_to_zero_threshold = sequence.processed_sequence_items.map<number>(o => o.set_to_zero_threshold ?? 0);
      smoothed_temp_1 = sequence.processed_sequence_items.map<number>(o => o.smoothed_temp_1 ?? 0);
      smoothed_humidity_1 = sequence.processed_sequence_items.map<number>(o => o.smoothed_humidity_1 ?? 0);
      ir_sensor_1 = sequence.processed_sequence_items.map<number>(o => o.ir_sensor_1 ?? 0);
      ir_sensor_2 = sequence.processed_sequence_items.map<number>(o => o.ir_sensor_2 ?? 0);
      // Available by checkbox (by default not plotted)
      battery_level = sequence.processed_sequence_items.map<number>(o => o.battery_level ?? 0);
      is_tamper = sequence.processed_sequence_items.map<number>(o => o.is_tamper ? 100 : 0);
      ambient_sensor_1 = sequence.processed_sequence_items.map<number>(o => o.ambient_sensor_1 ?? 0);
      ambient_sensor_2 = sequence.processed_sequence_items.map<number>(o => o.ambient_sensor_2 ?? 0);
      // The following are useful for research/analysis and only checked if something seems off.
      smoothed_voltage = sequence.processed_sequence_items.map<number>(o => o.smoothed_voltage ?? 0);
      smoothed_temp_2 = sequence.processed_sequence_items.map<number>(o => o.smoothed_temp_2 ?? 0);
      smoothed_humidity_2 = sequence.processed_sequence_items.map<number>(o => o.smoothed_humidity_2 ?? 0);
      final_graphene_sensor_data = sequence.processed_sequence_items.map<number>(o => o.final_graphene_sensor_data ?? 0);
      baseline_graphene_sensor_data = sequence.processed_sequence_items.map<number>(o => o.baseline_graphene_sensor_data ?? 0);
      tac_value = sequence.processed_sequence_items.map<number>(o => o.tac_value ?? 0);
    }

    this.chartData = {
      labels: activity_at,
      datasets: [
        {
          label: 'threshold',
          data: set_to_zero_threshold,
          yAxisID:'secondary',
          backgroundColor: 'rgba(17, 75, 95, 0.25)',
          borderColor: 'rgba(17, 75, 95, 1)',
        },
        {
          label: 'temp 1',
          data: smoothed_temp_1,
          yAxisID:'default',
          backgroundColor: 'rgba(159, 202, 0, 0.25)',
          borderColor: 'rgba(159, 202, 0, 1)',
        },
        {
          label: 'humidity 1',
          data: smoothed_humidity_1,
          yAxisID:'default',
          backgroundColor: 'rgba(112, 104, 177, 0.25)',
          borderColor: 'rgba(112, 104, 177, 1)',
        },
        {
          label: 'ir 1',
          data: ir_sensor_1,
          yAxisID:'secondary',
          backgroundColor: 'rgba(146, 230, 130, 0.25)',
          borderColor: 'rgba(146, 230, 130, 1)',
        },
        {
          label: 'ir 2',
          data: ir_sensor_2,
          yAxisID:'secondary',
          backgroundColor: 'rgba(249, 248, 113, 0.25)',
          borderColor: 'rgba(249, 248, 113, 1)',
        },
        {
          label: 'battery level',
          data: battery_level,
          yAxisID:'default',
          backgroundColor: 'rgba(189, 108, 179, 0.25)',
          borderColor: 'rgba(189, 108, 179, 1)',
          hidden: true,
        },
        {
          label: 'tamper',
          data: is_tamper,
          yAxisID:'default',
          backgroundColor: 'rgba(0, 148, 190, 0.25)',
          borderColor: 'rgba(0, 148, 190, 1)',
          hidden: true,
        },
        {
          label: 'amb 1',
          data: ambient_sensor_1,
          yAxisID:'secondary',
          backgroundColor: 'rgba(0, 169, 179, 0.25)',
          borderColor: 'rgba(0, 169, 179, 1)',
          hidden: true,
        },
        {
          label: 'amb 2',
          data: ambient_sensor_2,
          yAxisID:'secondary',
          backgroundColor: 'rgba(0, 202, 159, 0.25)',
          borderColor: 'rgba(0, 202, 159, 1)',
          hidden: true,
        },
        {
          label: 'voltage',
          data: smoothed_voltage,
          yAxisID:'default',
          backgroundColor: 'rgba(26, 147, 111, 0.25)',
          borderColor: 'rgba(26, 147, 111, 1)',
          hidden: true,
        },
        {
          label: 'temp 2',
          data: smoothed_temp_2,
          yAxisID:'default',
          backgroundColor: 'rgba(179, 169, 0, 0.25)',
          borderColor: 'rgba(179, 169, 0, 1)',
          hidden: true,
        },
        {
          label: 'humidity 2',
          data: smoothed_humidity_2,
          yAxisID:'default',
          backgroundColor: 'rgba(152, 106, 181, 0.25)',
          borderColor: 'rgba(152, 106, 181, 1)',
          hidden: true,
        },
        {
          label: 'final graphene',
          data: final_graphene_sensor_data,
          yAxisID:'secondary',
          backgroundColor: 'rgba(136, 212, 152, 0.25)',
          borderColor: 'rgba(136, 212, 152, 1)',
          hidden: true,
        },
        {
          label: 'baseline graphene',
          data: baseline_graphene_sensor_data,
          yAxisID:'secondary',
          backgroundColor: 'rgba(198, 218, 191, 0.25)',
          borderColor: 'rgba(198, 218, 191, 1)',
          hidden: true,
        },
        {
          label: 'TAC',
          data: tac_value,
          yAxisID:'secondary',
          backgroundColor: 'rgba(243, 233, 210, 0.25)',
          borderColor: 'rgba(243, 233, 210, 1)',
          hidden: true,
        },
      ]
    };
  }
}
