<head-meta [title]="this.title"></head-meta>
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <div class="navbar bg-base-100">
                <div class="flex-none">
                </div>
                <div class="flex-1">
                  <h1 class="h1">{{this.title}}</h1>
                  <form [formGroup]="this.searchForm">
                    <div class="form-control">
                        <div class="ml-4 input-group flex items-center gap-2 w-full max-w-md">
                            <input formControlName="serial_number" name="serial_number" type="text" placeholder="Serial Number" class="input input-bordered input-md w-40" />
                            <ng-container *ngrxLet="providers$ as providers; error as e; complete as c">
                                @if(c) {
                                    @if(providers.length > 0) {
                                        <select class="input input-bordered input-md  w-40 hidden xl:flex" formControlName="provider_id" name="provider_id">
                                            <option value="0">Any Provider</option>
                                            @for (provider of providers; track $index) {
                                                <option [value]="provider.id">{{ provider.name }}</option>
                                            }
                                        </select>
                                    }
                                }
                            </ng-container>
                            <ng-container *ngrxLet="deviceStatuses$ as deviceStatuses; error as e; complete as c">
                                @if(c) {
                                    @if(deviceStatuses.length > 0) {
                                        <select class="input input-bordered input-md  w-40 hidden xl:flex" formControlName="provider_id" name="provider_id">
                                            <option value="0">Any Device Status</option>
                                            @for (deviceStatus of deviceStatuses; track $index) {
                                                <option [value]="deviceStatus.id">{{ deviceStatus.name }}</option>
                                            }
                                        </select>
                                    }
                                }
                            </ng-container>
                            <button class="btn btn-square btn-md" [disabled]="!this.searchForm.valid" (click)="this.submit()">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                  </form>
                </div>
                <div class="flex-none">
                    @if(this.auth.has('device_create')) {
                        <button routerLink="/devices/create" routerLinkActive="active" class="btn btn-ghost text-primary"><fa-icon [icon]="faCirclePlus" class="w-5"></fa-icon> Add</button>
                    }
                </div>
            </div>
            <hr class="hr" />
            <table class="table table-zebra table-pin-rows">
                <ng-container *ngrxLet="devices$ as devices; error as e; complete as c">
                    <app-loading-error-block [error]="e?.error" [complete]="c" />
                    @if(c) {
                        <thead>
                            <th>Serial Number</th>
                            <th>Provider</th>
                            <th>Active</th>
                            <th>Status</th>
                            <th>Last Battery Level</th>
                            <th>Last Communication</th>
                        </thead>
                        <tbody>
                            @if(devices.length > 0) {
                                @for (device of devices; track $index) {
                                    <tr [routerLink]="'/devices/' + device.id" class="cursor-pointer">
                                        <td>{{ device.serial_number }}</td>
                                        <td>{{ device.provider.name }}</td>
                                        <td>{{ device.active ? 'Yes' : 'No' }}</td>
                                        <td>{{ device.device_status.name }}</td>
                                        <td>{{ device.last_battery_level }}</td>
                                        <td>{{ device.last_communicated_at | date }}</td>
                                    </tr>
                                }
                            } @else {
                                <tr>
                                    <td class="text-center" colspan="6">
                                        <span class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
                                            <span class="mt-2 block text-sm text-gray-400">No Devices</span>
                                        </span>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    }
                </ng-container>
            </table>
        </div>
    </div>
</article>