import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { Configuration } from '@lib/core';
import { environment } from '@env/environment';
import { AuthService } from './services/auth.service';
import { provideHttpClient } from '@angular/common/http';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes), 
    provideAnimationsAsync(),
    provideHttpClient(),
    {
      provide: Configuration,
      useFactory: (authService: AuthService) => new Configuration(
        {
          basePath: environment.apiUrl,
          credentials: { JWT: authService.getAccessToken.bind(authService) }
        }
      ),
      deps: [AuthService],
      multi: false
    },
    provideCharts(withDefaultRegisterables()),
  ]
};
