<head-meta [title]="this.title" />
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <ng-container *ngrxLet="deviceStatus$ as deviceStatus; error as e; complete as c">
                @if(deviceStatus) {
                    <div class="navbar bg-base-100">
                        <div class="flex-none">
                          <a routerLink="/sensor-types" routerLinkActive="active" class="btn btn-square btn-ghost text-primary"><fa-icon [icon]="faArrowLeft" class="w-5"></fa-icon></a>
                        </div>
                        <div class="flex-1">
                          <h1 class="h1 ml-4">{{this.deviceStatus.name}}</h1>
                        </div>
                        <div class="flex-none">
                            @if(this.auth.has('sensor_type_update')) {
                                <button [routerLink]="'/sensor-types/' + this.id + '/edit'" routerLinkActive="active" class="btn btn-ghost text-primary">
                                    <fa-icon [icon]="faPenToSquare" class="w-5"></fa-icon> Edit
                                </button>
                            }
                        </div>
                    </div>
                    <hr class="hr" />
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="border-t border-gray-100">
                            <dl class="divide-y divide-gray-100">
                                <div>
                                    <app-detail-display title="Name" [value]="deviceStatus.name" />
                                </div>
                                <div>
                                    <app-detail-display title="Internal Id" [value]="deviceStatus.internal_id.toString()" />
                                </div>
                                <div>
                                    <app-created-updated-display [entity]="deviceStatus" />
                                </div>
                            </dl>
                        </div>
                    </div>
                }
                <app-loading-error-block [error]="e?.error" [complete]="c" />
            </ng-container>
        </div>
    </div>
</article>