/**
 * Arborsense API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 12.2.3 (519615d)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { Devices } from '../model/devices';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface DevicesDeleteRequestParams {
    id?: string;
    providerId?: string;
    serialNumber?: string;
    sensor?: string;
    primaryPcb?: string;
    pin?: string;
    active?: string;
    lastBatteryLevel?: string;
    lastCommunicatedAt?: string;
    createdAt?: string;
    updatedAt?: string;
    versionId?: string;
    slopeETest?: string;
    baselineCurrent?: string;
    deviceStatusId?: string;
    lastTimestamp?: string;
    lastSensorVoltage?: string;
    lastMode?: string;
    lastDeviceConnected?: string;
    /** Preference */
    prefer?: 'return=representation' | 'return=minimal' | 'return=none';
}

export interface DevicesGetRequestParams {
    id?: string;
    providerId?: string;
    serialNumber?: string;
    sensor?: string;
    primaryPcb?: string;
    pin?: string;
    active?: string;
    lastBatteryLevel?: string;
    lastCommunicatedAt?: string;
    createdAt?: string;
    updatedAt?: string;
    versionId?: string;
    slopeETest?: string;
    baselineCurrent?: string;
    deviceStatusId?: string;
    lastTimestamp?: string;
    lastSensorVoltage?: string;
    lastMode?: string;
    lastDeviceConnected?: string;
    /** Filtering Columns */
    select?: string;
    /** Ordering */
    order?: string;
    /** Limiting and Pagination */
    range?: string;
    /** Limiting and Pagination */
    rangeUnit?: string;
    /** Limiting and Pagination */
    offset?: string;
    /** Limiting and Pagination */
    limit?: string;
    /** Preference */
    prefer?: 'count=none';
}

export interface DevicesPatchRequestParams {
    id?: string;
    providerId?: string;
    serialNumber?: string;
    sensor?: string;
    primaryPcb?: string;
    pin?: string;
    active?: string;
    lastBatteryLevel?: string;
    lastCommunicatedAt?: string;
    createdAt?: string;
    updatedAt?: string;
    versionId?: string;
    slopeETest?: string;
    baselineCurrent?: string;
    deviceStatusId?: string;
    lastTimestamp?: string;
    lastSensorVoltage?: string;
    lastMode?: string;
    lastDeviceConnected?: string;
    /** Preference */
    prefer?: 'return=representation' | 'return=minimal' | 'return=none';
    /** devices */
    devices?: Devices;
}

export interface DevicesPostRequestParams {
    /** Filtering Columns */
    select?: string;
    /** Preference */
    prefer?: 'return=representation' | 'return=minimal' | 'return=none' | 'resolution=ignore-duplicates' | 'resolution=merge-duplicates';
    /** devices */
    devices?: Devices;
}


@Injectable({
  providedIn: 'root'
})
export class DevicesService {

    protected basePath = 'http://0.0.0.0:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public devicesDelete(requestParameters: DevicesDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public devicesDelete(requestParameters: DevicesDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public devicesDelete(requestParameters: DevicesDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public devicesDelete(requestParameters: DevicesDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        const providerId = requestParameters.providerId;
        const serialNumber = requestParameters.serialNumber;
        const sensor = requestParameters.sensor;
        const primaryPcb = requestParameters.primaryPcb;
        const pin = requestParameters.pin;
        const active = requestParameters.active;
        const lastBatteryLevel = requestParameters.lastBatteryLevel;
        const lastCommunicatedAt = requestParameters.lastCommunicatedAt;
        const createdAt = requestParameters.createdAt;
        const updatedAt = requestParameters.updatedAt;
        const versionId = requestParameters.versionId;
        const slopeETest = requestParameters.slopeETest;
        const baselineCurrent = requestParameters.baselineCurrent;
        const deviceStatusId = requestParameters.deviceStatusId;
        const lastTimestamp = requestParameters.lastTimestamp;
        const lastSensorVoltage = requestParameters.lastSensorVoltage;
        const lastMode = requestParameters.lastMode;
        const lastDeviceConnected = requestParameters.lastDeviceConnected;
        const prefer = requestParameters.prefer;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (providerId !== undefined && providerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>providerId, 'provider_id');
        }
        if (serialNumber !== undefined && serialNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>serialNumber, 'serial_number');
        }
        if (sensor !== undefined && sensor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sensor, 'sensor');
        }
        if (primaryPcb !== undefined && primaryPcb !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>primaryPcb, 'primary_pcb');
        }
        if (pin !== undefined && pin !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pin, 'pin');
        }
        if (active !== undefined && active !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>active, 'active');
        }
        if (lastBatteryLevel !== undefined && lastBatteryLevel !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastBatteryLevel, 'last_battery_level');
        }
        if (lastCommunicatedAt !== undefined && lastCommunicatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastCommunicatedAt, 'last_communicated_at');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'created_at');
        }
        if (updatedAt !== undefined && updatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>updatedAt, 'updated_at');
        }
        if (versionId !== undefined && versionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>versionId, 'version_id');
        }
        if (slopeETest !== undefined && slopeETest !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>slopeETest, 'slope_e_test');
        }
        if (baselineCurrent !== undefined && baselineCurrent !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>baselineCurrent, 'baseline_current');
        }
        if (deviceStatusId !== undefined && deviceStatusId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deviceStatusId, 'device_status_id');
        }
        if (lastTimestamp !== undefined && lastTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastTimestamp, 'last_timestamp');
        }
        if (lastSensorVoltage !== undefined && lastSensorVoltage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastSensorVoltage, 'last_sensor_voltage');
        }
        if (lastMode !== undefined && lastMode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastMode, 'last_mode');
        }
        if (lastDeviceConnected !== undefined && lastDeviceConnected !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastDeviceConnected, 'last_device_connected');
        }

        let localVarHeaders = this.defaultHeaders;
        if (prefer !== undefined && prefer !== null) {
            localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/devices`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public devicesGet(requestParameters: DevicesGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<Array<Devices>>;
    public devicesGet(requestParameters: DevicesGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<Devices>>>;
    public devicesGet(requestParameters: DevicesGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<Devices>>>;
    public devicesGet(requestParameters: DevicesGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        const providerId = requestParameters.providerId;
        const serialNumber = requestParameters.serialNumber;
        const sensor = requestParameters.sensor;
        const primaryPcb = requestParameters.primaryPcb;
        const pin = requestParameters.pin;
        const active = requestParameters.active;
        const lastBatteryLevel = requestParameters.lastBatteryLevel;
        const lastCommunicatedAt = requestParameters.lastCommunicatedAt;
        const createdAt = requestParameters.createdAt;
        const updatedAt = requestParameters.updatedAt;
        const versionId = requestParameters.versionId;
        const slopeETest = requestParameters.slopeETest;
        const baselineCurrent = requestParameters.baselineCurrent;
        const deviceStatusId = requestParameters.deviceStatusId;
        const lastTimestamp = requestParameters.lastTimestamp;
        const lastSensorVoltage = requestParameters.lastSensorVoltage;
        const lastMode = requestParameters.lastMode;
        const lastDeviceConnected = requestParameters.lastDeviceConnected;
        const select = requestParameters.select;
        const order = requestParameters.order;
        const range = requestParameters.range;
        const rangeUnit = requestParameters.rangeUnit;
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;
        const prefer = requestParameters.prefer;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (providerId !== undefined && providerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>providerId, 'provider_id');
        }
        if (serialNumber !== undefined && serialNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>serialNumber, 'serial_number');
        }
        if (sensor !== undefined && sensor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sensor, 'sensor');
        }
        if (primaryPcb !== undefined && primaryPcb !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>primaryPcb, 'primary_pcb');
        }
        if (pin !== undefined && pin !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pin, 'pin');
        }
        if (active !== undefined && active !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>active, 'active');
        }
        if (lastBatteryLevel !== undefined && lastBatteryLevel !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastBatteryLevel, 'last_battery_level');
        }
        if (lastCommunicatedAt !== undefined && lastCommunicatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastCommunicatedAt, 'last_communicated_at');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'created_at');
        }
        if (updatedAt !== undefined && updatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>updatedAt, 'updated_at');
        }
        if (versionId !== undefined && versionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>versionId, 'version_id');
        }
        if (slopeETest !== undefined && slopeETest !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>slopeETest, 'slope_e_test');
        }
        if (baselineCurrent !== undefined && baselineCurrent !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>baselineCurrent, 'baseline_current');
        }
        if (deviceStatusId !== undefined && deviceStatusId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deviceStatusId, 'device_status_id');
        }
        if (lastTimestamp !== undefined && lastTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastTimestamp, 'last_timestamp');
        }
        if (lastSensorVoltage !== undefined && lastSensorVoltage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastSensorVoltage, 'last_sensor_voltage');
        }
        if (lastMode !== undefined && lastMode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastMode, 'last_mode');
        }
        if (lastDeviceConnected !== undefined && lastDeviceConnected !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastDeviceConnected, 'last_device_connected');
        }
        if (select !== undefined && select !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>select, 'select');
        }
        if (order !== undefined && order !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>order, 'order');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }

        let localVarHeaders = this.defaultHeaders;
        if (range !== undefined && range !== null) {
            localVarHeaders = localVarHeaders.set('Range', String(range));
        }
        if (rangeUnit !== undefined && rangeUnit !== null) {
            localVarHeaders = localVarHeaders.set('Range-Unit', String(rangeUnit));
        }
        if (prefer !== undefined && prefer !== null) {
            localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'application/vnd.pgrst.object+json;nulls=stripped',
                'application/vnd.pgrst.object+json',
                'text/csv'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/devices`;
        return this.httpClient.request<Array<Devices>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public devicesPatch(requestParameters: DevicesPatchRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public devicesPatch(requestParameters: DevicesPatchRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public devicesPatch(requestParameters: DevicesPatchRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public devicesPatch(requestParameters: DevicesPatchRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        const providerId = requestParameters.providerId;
        const serialNumber = requestParameters.serialNumber;
        const sensor = requestParameters.sensor;
        const primaryPcb = requestParameters.primaryPcb;
        const pin = requestParameters.pin;
        const active = requestParameters.active;
        const lastBatteryLevel = requestParameters.lastBatteryLevel;
        const lastCommunicatedAt = requestParameters.lastCommunicatedAt;
        const createdAt = requestParameters.createdAt;
        const updatedAt = requestParameters.updatedAt;
        const versionId = requestParameters.versionId;
        const slopeETest = requestParameters.slopeETest;
        const baselineCurrent = requestParameters.baselineCurrent;
        const deviceStatusId = requestParameters.deviceStatusId;
        const lastTimestamp = requestParameters.lastTimestamp;
        const lastSensorVoltage = requestParameters.lastSensorVoltage;
        const lastMode = requestParameters.lastMode;
        const lastDeviceConnected = requestParameters.lastDeviceConnected;
        const prefer = requestParameters.prefer;
        const devices = requestParameters.devices;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (providerId !== undefined && providerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>providerId, 'provider_id');
        }
        if (serialNumber !== undefined && serialNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>serialNumber, 'serial_number');
        }
        if (sensor !== undefined && sensor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sensor, 'sensor');
        }
        if (primaryPcb !== undefined && primaryPcb !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>primaryPcb, 'primary_pcb');
        }
        if (pin !== undefined && pin !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pin, 'pin');
        }
        if (active !== undefined && active !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>active, 'active');
        }
        if (lastBatteryLevel !== undefined && lastBatteryLevel !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastBatteryLevel, 'last_battery_level');
        }
        if (lastCommunicatedAt !== undefined && lastCommunicatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastCommunicatedAt, 'last_communicated_at');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'created_at');
        }
        if (updatedAt !== undefined && updatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>updatedAt, 'updated_at');
        }
        if (versionId !== undefined && versionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>versionId, 'version_id');
        }
        if (slopeETest !== undefined && slopeETest !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>slopeETest, 'slope_e_test');
        }
        if (baselineCurrent !== undefined && baselineCurrent !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>baselineCurrent, 'baseline_current');
        }
        if (deviceStatusId !== undefined && deviceStatusId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deviceStatusId, 'device_status_id');
        }
        if (lastTimestamp !== undefined && lastTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastTimestamp, 'last_timestamp');
        }
        if (lastSensorVoltage !== undefined && lastSensorVoltage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastSensorVoltage, 'last_sensor_voltage');
        }
        if (lastMode !== undefined && lastMode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastMode, 'last_mode');
        }
        if (lastDeviceConnected !== undefined && lastDeviceConnected !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastDeviceConnected, 'last_device_connected');
        }

        let localVarHeaders = this.defaultHeaders;
        if (prefer !== undefined && prefer !== null) {
            localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/vnd.pgrst.object+json;nulls=stripped',
            'application/vnd.pgrst.object+json',
            'text/csv'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/devices`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: devices,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public devicesPost(requestParameters: DevicesPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public devicesPost(requestParameters: DevicesPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public devicesPost(requestParameters: DevicesPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public devicesPost(requestParameters: DevicesPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const select = requestParameters.select;
        const prefer = requestParameters.prefer;
        const devices = requestParameters.devices;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (select !== undefined && select !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>select, 'select');
        }

        let localVarHeaders = this.defaultHeaders;
        if (prefer !== undefined && prefer !== null) {
            localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/vnd.pgrst.object+json;nulls=stripped',
            'application/vnd.pgrst.object+json',
            'text/csv'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/devices`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: devices,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
