<head-meta [title]="this.title" />
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <ng-container *ngrxLet="algorithm$ as algorithm; error as e; complete as c">
                @if(algorithm) {
                    <div class="navbar bg-base-100">
                        <div class="flex-none">
                          <a routerLink="/algorithms" routerLinkActive="active" class="btn btn-square btn-ghost text-primary"><fa-icon [icon]="faArrowLeft" class="w-5"></fa-icon></a>
                        </div>
                        <div class="flex-1">
                          <h1 class="h1 ml-4">{{this.algorithm.name}}</h1>
                        </div>
                        <div class="flex-none">
                            @if(this.auth.has('algorithm_update')) {
                                <button [routerLink]="'/algorithms/' + this.id + '/edit'" routerLinkActive="active" class="btn btn-ghost text-primary">
                                    <fa-icon [icon]="faPenToSquare" class="w-5"></fa-icon> Edit
                                </button>
                            }
                        </div>
                    </div>
                    <hr class="hr" />
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="border-t border-gray-100">
                            <dl class="divide-y divide-gray-100">
                                <div>
                                    <app-detail-display title="Internal Id" [value]="algorithm.internal_id.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Plot Frequency" [value]="algorithm.plot_frequency.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Use Fahrenheit" [value]="algorithm.use_fahrenheit ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Graph Tac" [value]="algorithm.graph_tac ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Graph Environment" [value]="algorithm.graph_environment ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Graph Baseline" [value]="algorithm.graph_baseline ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Graph Bio" [value]="algorithm.graph_bio ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Graph Ambient" [value]="algorithm.graph_ambient ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Graph Tamper" [value]="algorithm.graph_tamper ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Graph Battery" [value]="algorithm.graph_battery ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Min Peak Height" [value]="algorithm.min_peak_height.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Min Peak Prominence" [value]="algorithm.min_peak_prominence.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Min Peak Hour Height" [value]="algorithm.min_peak_hour_height.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Min Peak Half Hour Height" [value]="algorithm.min_peak_half_hour_height.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Max Peak Rise Slope" [value]="algorithm.max_peak_rise_slope.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Max Peak Fall Slope" [value]="algorithm.max_peak_fall_slope.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Exclude Dqd Alcohol Events" [value]="algorithm.exclude_dqd_alcohol_events ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Discard Initial Data Hours" [value]="algorithm.discard_initial_data_hours.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Max Time Without Reading" [value]="algorithm.max_time_without_reading.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Percentile Filter Window" [value]="algorithm.percentile_filter_window.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Flatten Tac" [value]="algorithm.flatten_tac ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Rh Slope 1" [value]="algorithm.rh_slope_1.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Rh Slope 2" [value]="algorithm.rh_slope_2.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Gain Resistance" [value]="algorithm.gain_resistance.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Correction Factor 5" [value]="algorithm.correction_factor_5.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Neg Values To Zero" [value]="algorithm.neg_values_to_zero ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Relative Humidity Synth Threshold" [value]="algorithm.relative_humidity_synth_threshold.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Temp Correction Date Chunk Size" [value]="algorithm.temp_correction_date_chunk_size.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Temp Correction Rh Range Size" [value]="algorithm.temp_correction_rh_range_size.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Points Of Window" [value]="algorithm.points_of_window.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Smooth Factor" [value]="algorithm.smooth_factor.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Threshold" [value]="algorithm.threshold.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Tac Threshold" [value]="algorithm.tac_threshold.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Percentile Filtering" [value]="algorithm.percentile_filtering ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Fft Smoothing" [value]="algorithm.fft_smoothing ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Rh Correction" [value]="algorithm.rh_correction ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Temp Correction" [value]="algorithm.temp_correction ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Baseline Correction" [value]="algorithm.baseline_correction ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Delta Threshold" [value]="algorithm.delta_threshold.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Distance Between Peaks" [value]="algorithm.distance_between_peaks.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Savgol Filter Window" [value]="algorithm.savgol_filter_window.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Savgol Filter Polynomial" [value]="algorithm.savgol_filter_polynomial.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Factor B1" [value]="algorithm.factor_b1.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Factor A1" [value]="algorithm.factor_a1.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Alpha" [value]="algorithm.alpha.toString()" />
                                </div>
                                <div>
                                    <app-created-updated-display [entity]="algorithm" />
                                </div>
                            </dl>
                        </div>
                    </div>
                }
                <app-loading-error-block [error]="e?.error" [complete]="c" />
            </ng-container>
        </div>
    </div>
</article>