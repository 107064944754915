

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="phoneForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="name"
                        [form]="phoneForm"
                        label="Name"
                        [errorMessages]="{ required: 'Name is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="provider_id"
                        type="select|providers"
                        [form]="phoneForm"
                        label="Provider"
                        [errorMessages]="{ required: 'Provider is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="current_device_id"
                        type="select|devices"
                        [form]="phoneForm"
                        label="Current Device"
                        [errorMessages]="{ required: 'Current device is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="current_user_id"
                        type="select|users"
                        [form]="phoneForm"
                        label="Current User"
                        [errorMessages]="{ required: 'Current user is required'}"
                    />
                </div>
                <!--
                <div>
                    <app-input-field
                        controlName="current_sequence_id"
                        type="select|sequences"
                        [form]="phoneForm"
                        label="Current Sequence"
                        [errorMessages]="{ required: 'Current sequence is required'}"
                    />
                </div>
                -->
                <div>
                    <app-input-field
                        controlName="unique_id"
                        [form]="phoneForm"
                        label="Unique Id"
                        [errorMessages]="{ required: 'Unique Id is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="mfg"
                        [form]="phoneForm"
                        label="Manufactor"
                        [errorMessages]="{ required: 'Manufactor is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="model"
                        [form]="phoneForm"
                        label="Model"
                        [errorMessages]="{ required: 'Model is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="os"
                        [form]="phoneForm"
                        label="OS"
                        [errorMessages]="{ required: 'OS is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="os_version"
                        [form]="phoneForm"
                        label="OS version"
                        [errorMessages]="{ required: 'OS version is required'}"
                    />
                </div>
            </div>
        </form>
    </div>
</div>