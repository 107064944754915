import { Component, signal } from '@angular/core';
import { FirmwareFormComponent } from "@app/components/forms/firmware/form.component";
import { FirmwareHelperService } from '@app/services/helpers/firmware-helper.service';
import { Router, RouterModule } from '@angular/router';
import { AppHttpError } from '@app/interfaces/pg-models.interface';
import { FirmwareCreate } from '@app/models/firmware.interface';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { HeadMetaComponent } from '@app/components/ui.component';
import { ToastService } from '@app/services/toast.service';

@Component({
  selector: 'app-firmware-create',
  standalone: true,
  templateUrl: './create.page.html',
  styleUrl: './create.page.scss',
  imports: [
    HeadMetaComponent,
    FirmwareFormComponent,
    RouterModule,
    FontAwesomeModule,
  ]
})
export class FirmwareCreatePage {
  public title = 'New Firmware';
  formValid = signal(false);
  formValue = signal<FirmwareCreate | null>(null);

  faArrowLeft = faArrowLeft;

  constructor(
    private firmwareService: FirmwareHelperService,
    private router: Router,
    private toast: ToastService,
  ) {
    
  }

  create() {
    let val = this.formValue();
    if(val) {
      this.firmwareService.create(val)
        .subscribe({
          next: id => {
            console.log(id)
            if(id) {
              this.router.navigateByUrl('/firmware/' + id);
            }
          },
          error: (err: AppHttpError) => {
            console.log(err.error)
            this.toast.message({ message: err.error.code + ": " + err.error.message, type: 'error'});
          }
        });
    }
  }
}
