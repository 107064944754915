<head-meta title="Dashboard"></head-meta>
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <h1 class="h1">Dashboard</h1>
            <hr class="hr" />
            <p>Welcome to Arborsense Cloud Platform!</p>

            @if(this.auth.has('provider_read')){
                <section class="stats stats-vertical col-span-12 w-full shadow-md xl:stats-horizontal">
                    <div class="stat min-w-48 lg:min-w-24">
                        <div class="stat-title text-accent">Devices</div>
                        <div class="stat-value text-primary">0</div>
                        <div class="stat-desc">available &amp; in use</div>
                    </div>
                    <div class="stat min-w-48 lg:min-w-24">
                        <div class="stat-title text-accent">Sequences</div>
                        <div class="stat-value text-primary">0</div>
                        <div class="stat-desc">currently active</div>
                    </div>
                    <div class="stat min-w-48 lg:min-w-24">
                        <div class="stat-title text-accent">Alerts Generated</div>
                        <div class="stat-value text-primary">0</div>
                        <div class="stat-desc">for active sequences</div>
                    </div>
                    <div class="stat min-w-48 lg:min-w-24">
                        <div class="stat-title text-accent">Webhooks Delivered</div>
                        <div class="stat-value text-primary">0</div>
                        <div class="stat-desc">for active sequences</div>
                    </div>
                    <div class="stat min-w-48 lg:min-w-24">
                        <div class="stat-title text-accent">Data Points</div>
                        <div class="stat-value text-primary">0</div>
                        <div class="stat-desc">for active sequences</div>
                    </div>
                </section>
            }
            
            <!--
                Most recent sequences/activity
            -->
        </div>
    </div>
</article>