import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingErrorBlockComponent } from '@app/components/loading-error-block/loading-error-block.component';
import { HeadMetaComponent } from '@app/components/ui.component';
import { Firmware } from '@app/models/firmware.interface';
import { AuthService } from '@app/services/auth.service';
import { FirmwareHelperService } from '@app/services/helpers/firmware-helper.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { 
  faCirclePlus,
  faArrowLeft 
 } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-firmware',
  standalone: true,
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent,
    DatePipe,
    FontAwesomeModule,
    RouterModule,
  ],
  templateUrl: './listing.page.html',
  styleUrl: './listing.page.scss'
})
export class FirmwarePage {
  public title = 'Firmware';
  firmware$: Observable<Firmware[]>;

  faCirclePlus = faCirclePlus;
  faArrowLeft = faArrowLeft;

  constructor(
    private firmwareHelper: FirmwareHelperService,
    public auth: AuthService,
  ) {
    this.firmware$ = this.firmwareHelper.getAll();
  }
}
