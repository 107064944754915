

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="sequenceForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="device_id"
                        type="select|devices"
                        [form]="sequenceForm"
                        label="Device"
                        [errorMessages]="{ required: 'Device is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="provider_id"
                        type="select|providers"
                        [form]="sequenceForm"
                        label="Provider"
                        [errorMessages]="{ required: 'Provider is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="is_processing_queued"
                        type="toggle"
                        [form]="sequenceForm"
                        label="Queued for Processing?"
                    />
                </div>
                <!--
                <div>
                    <app-input-field
                        controlName="started_at"
                        [form]="sequenceForm"
                        type="datetime-local"
                        label="Started at"
                        [errorMessages]="{ required: 'Started at is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="last_activity_at"
                        [form]="sequenceForm"
                        type="datetime-local"
                        label="Last activity at"
                        [errorMessages]="{ required: 'Last activity at is required'}"
                    />
                </div>
                -->
                <div>
                    <app-input-field
                        controlName="ended_at"
                        [form]="sequenceForm"
                        type="datetime-local"
                        label="End at"
                        [errorMessages]="{ required: 'End at is required'}"
                    />
                </div>
            </div>
        </form>
    </div>
</div>