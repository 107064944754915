<head-meta [title]="this.title" />
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <ng-container *ngrxLet="phone$ as phone; error as e; complete as c">
                @if(phone) {
                    <div class="navbar bg-base-100">
                        <div class="flex-none">
                          <a routerLink="/phones" routerLinkActive="active" class="btn btn-square btn-ghost text-primary"><fa-icon [icon]="faArrowLeft" class="w-5"></fa-icon></a>
                        </div>
                        <div class="flex-1">
                          <h1 class="h1 ml-4">{{this.phone.name}}</h1>
                        </div>
                        <div class="flex-none">
                            @if(this.auth.has('phone_update')) {
                                <button [routerLink]="'/phones/' + this.id + '/edit'" routerLinkActive="active" class="btn btn-ghost text-primary">
                                    <fa-icon [icon]="faPenToSquare" class="w-5"></fa-icon> Edit
                                </button>
                            }
                        </div>
                    </div>
                    <hr class="hr" />
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="border-t border-gray-100">
                            <dl class="divide-y divide-gray-100">
                                <div>
                                    <app-detail-display title="Name" [value]="phone.name" />
                                </div>
                                <div>
                                    <app-detail-display title="Provider" [value]="phone.provider.name" />
                                </div>
                                <div>
                                    <app-detail-display title="Current Device" [value]="phone.device.serial_number" />
                                </div>
                                <div>
                                    <app-detail-display title="Current User" [value]="phone.user.display_name ?? ''" />
                                </div>
                                <!--
                                current_sequence_id
                                -->
                                <div>
                                    <app-detail-display title="Unique Id" [value]="phone.unique_id" />
                                </div>
                                <div>
                                    <app-detail-display title="Manufactor" [value]="phone.mfg" />
                                </div>
                                <div>
                                    <app-detail-display title="Model" [value]="phone.model" />
                                </div>
                                <div>
                                    <app-detail-display title="OS" [value]="phone.os" />
                                </div>
                                <div>
                                    <app-detail-display title="OS version" [value]="phone.os_version" />
                                </div>
                                <div>
                                    <app-created-updated-display [entity]="phone" />
                                </div>
                            </dl>
                        </div>
                    </div>
                }
                <app-loading-error-block [error]="e?.error" [complete]="c" />
            </ng-container>
        </div>
    </div>
</article>