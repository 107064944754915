import { Component, signal } from '@angular/core';
import { UserHelperService } from '@app/services/helpers/user-helper.service';
import { Router, RouterModule } from '@angular/router';
import { AppHttpError } from '@app/interfaces/pg-models.interface';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { HeadMetaComponent } from '@app/components/ui.component';
import { UserCreateFormComponent } from '@app/components/forms/user-create/form.component';
import { RpcCreateUserPostRequest } from '@lib/core';
import { ToastService } from '@app/services/toast.service';

@Component({
  selector: 'app-user-create',
  standalone: true,
  templateUrl: './create.page.html',
  styleUrl: './create.page.scss',
  imports: [
    HeadMetaComponent,
    UserCreateFormComponent,
    RouterModule,
    FontAwesomeModule,
  ]
})
export class UserCreatePage {
  public title = 'New User';
  formValid = signal(false);
  formValue = signal<RpcCreateUserPostRequest | null>(null);

  faArrowLeft = faArrowLeft;

  constructor(
    private userService: UserHelperService,
    private router: Router,
    private toast: ToastService,
  ) {
    
  }

  create() {
    let val = this.formValue();
    console.log(val)
    if(val) {
      this.userService.create(val)
        .subscribe({
          next: id => {
            console.log(id)
            if(id) {
              this.router.navigateByUrl('/users/' + id);
            }
          },
          error: (err: AppHttpError) => {
            console.log(err.error)
            this.toast.message({ message: err.error.code + ": " + err.error.message, type: 'error'});
          }
        });
    }
  }
}
