<head-meta [title]="this.title"></head-meta>
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <div class="navbar bg-base-100">
                <div class="flex-none">
                  <a routerLink="/device-statuses" routerLinkActive="active" class="btn btn-square btn-ghost text-primary"><fa-icon [icon]="faArrowLeft" class="w-5"></fa-icon></a>
                </div>
                <div class="flex-1">
                  <h1 class="h1 ml-4">{{this.title}}</h1>
                </div>
                <div class="flex-none"></div>
            </div>
            <hr class="hr" />
            <app-device-status-form 
                (formValid)="formValid.set($event)"
                (formChange)="formValue.set($event)"
            />
            <button (click)="create()" class="btn btn-primary" [disabled]="!formValid()">Create</button>
        </div>
    </div>
</article>
