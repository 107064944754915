/**
 * Arborsense API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 12.2.3 (519615d)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Devices { 
    /**
     * Note: This is a Primary Key.<pk/>
     */
    id: number;
    /**
     * Note: This is a Foreign Key to `providers.id`.<fk table=\'providers\' column=\'id\'/>
     */
    provider_id?: number;
    serial_number: string;
    sensor: string;
    primary_pcb: string;
    pin: string;
    active: boolean;
    last_battery_level: number;
    last_communicated_at?: string;
    created_at?: string;
    updated_at?: string;
    /**
     * Note: This is a Foreign Key to `versions.id`.<fk table=\'versions\' column=\'id\'/>
     */
    version_id: number;
    slope_e_test: number;
    baseline_current: number;
    /**
     * Note: This is a Foreign Key to `device_statuses.id`.<fk table=\'device_statuses\' column=\'id\'/>
     */
    device_status_id: number;
    last_timestamp?: string;
    last_sensor_voltage?: number;
    last_mode?: Devices.LastModeEnum;
    last_device_connected: boolean;
}
export namespace Devices {
    export type LastModeEnum = 'idle' | 'live' | 'record';
    export const LastModeEnum = {
        Idle: 'idle' as LastModeEnum,
        Live: 'live' as LastModeEnum,
        Record: 'record' as LastModeEnum
    };
}


