/**
 * Arborsense API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 12.2.3 (519615d)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DeviceStates { 
    /**
     * Note: This is a Primary Key.<pk/>
     */
    id: number;
    /**
     * Note: This is a Foreign Key to `devices.id`.<fk table=\'devices\' column=\'id\'/>
     */
    device_id: number;
    battery_level: number;
    device_timestamp?: string;
    sensor_voltage?: number;
    mode?: DeviceStates.ModeEnum;
    created_at?: string;
    updated_at?: string;
    is_device_connected: boolean;
}
export namespace DeviceStates {
    export type ModeEnum = 'idle' | 'live' | 'record';
    export const ModeEnum = {
        Idle: 'idle' as ModeEnum,
        Live: 'live' as ModeEnum,
        Record: 'record' as ModeEnum
    };
}


