<div class="drawer min-h-screen bg-base-200 lg:drawer-open">
    <input id="my-drawer" type="checkbox" class="drawer-toggle" />
    <main class="drawer-content">
        <div class="navbar bg-secondary text-primary-content px-2">
            <div class="flex-none">
                <label for="my-drawer" class="btn btn-square btn-ghost drawer-button lg:hidden">
                    <fa-icon [icon]="faBars"></fa-icon>
                </label>
            </div>
            <div class="flex-1">
              <h1 class="lg:text-2xl lg:font-light px-2 lg:hidden"><img routerLink="/dashboard" src="/assets/arborsense-white.png" class="h-8" /></h1>
            </div>
            <div class="flex-none">
                <div class="dropdown-end dropdown z-10">
                    <div tabindex="0" class="btn btn-square btn-ghost">
                        <div class="w-10 rounded-full">
                            <fa-icon [icon]="faUser" class="w-10"></fa-icon>
                        </div>
                    </div>
                    <ul
                        tabindex="0"
                        class="menu dropdown-content mt-3 w-52 rounded-box bg-base-100 p-2 shadow-2xl text-black">
                        <li><a routerLink="/users/{{this.getUserId()}}/edit" routerLinkActive="active"><fa-icon [icon]="faUser" class="w-5"></fa-icon> My Profile</a></li>
                        @if(this.auth.has('developer_docs')) {
                            <li><a routerLink="/docs" routerLinkActive="active"><fa-icon [icon]="faFileCode" class="w-5"></fa-icon> API Documentation</a></li>
                        }
                        <li><a (click)="this.logout()"><fa-icon [icon]="faSignOut" class="w-5"></fa-icon> Logout</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 my-6 lg:my-8">
            <router-outlet></router-outlet>
        </div>
    </main>
    <aside class="drawer-side z-10">
        <label for="my-drawer" class="drawer-overlay"></label>
        <nav class="flex min-h-screen w-80 flex-col gap-2 overflow-y-auto bg-primary text-primary-content px-1 py-6">
            <div class="mx-4 flex items-center gap-2 font-black">
                <img routerLink="/dashboard" src="/assets/arborsense-white.png" class="h-12" />
            </div>
            <ul class="main-menu menu">
                <li>
                    <a routerLink="/dashboard" routerLinkActive="active">
                        <fa-icon [icon]="faHome" class="w-5"></fa-icon>
                        Dashboard
                    </a>
                </li>
                <li>
                    <details>
                        <summary>
                            <fa-icon [icon]="faBuilding" class="w-5"></fa-icon>
                                Providers
                        </summary>
                        <ul>
                            @if(this.auth.has('provider_read')) {
                                <li><a routerLink="/providers" routerLinkActive="active"><fa-icon [icon]="faBuilding" class="w-5"></fa-icon> Providers</a></li>
                            }
                            @if(this.auth.has('webhook_read')) {
                                <li><a routerLink="/webhooks" routerLinkActive="active"><fa-icon [icon]="faCodeBranch" class="w-5"></fa-icon> Webhooks</a></li>
                            }
                            @if(this.auth.has('phone_read')) {
                                <li><a routerLink="/phones" routerLinkActive="active"><fa-icon [icon]="faMobileAlt" class="w-5"></fa-icon> Phones</a></li>
                            }
                            <li><a routerLink="/users" routerLinkActive="active"><fa-icon [icon]="faUsers" class="w-5"></fa-icon> Users</a></li>
                        </ul>
                    </details>
                </li>
                @if(this.auth.has('device_read')) {
                    <li>
                        <a routerLink="/devices" routerLinkActive="active">
                            <fa-icon [icon]="faRing" class="w-5"></fa-icon>
                            Devices
                        </a>
                    </li>
                }
                @if(this.auth.has('sequence_read')) {
                    <li>
                        <a routerLink="/sequences" routerLinkActive="active">
                            <fa-icon [icon]="faHourglass" class="w-5"></fa-icon> 
                            Sequences
                        </a>
                    </li>
                }
                <!--
                <li>
                    <details>
                        <summary>
                            <fa-icon [icon]="faCircleCheck" class="w-5"></fa-icon>
                            Data Management
                        </summary>
                        <ul>
                            @if(this.auth.has('sequence_read')) {
                                <li><a routerLink="/sequences" routerLinkActive="active"><fa-icon [icon]="faHourglass" class="w-5"></fa-icon> All Sequences</a></li>
                            }
                            @if(this.auth.has('sequence_event_read')) {
                                <li><a routerLink="/sequence-events" routerLinkActive="active"><fa-icon [icon]="faCalendarCheck" class="w-5"></fa-icon> Search Sequence Events</a></li>
                            }
                            @if(this.auth.has('tamper_event_read')) {
                                <li><a routerLink="/tamper-events" routerLinkActive="active"><fa-icon [icon]="faUserSecret" class="w-5"></fa-icon> Search Tamper Events</a></li>
                            }
                            @if(this.auth.has('peak_event_read')) {
                                <li><a routerLink="/peak-events" routerLinkActive="active"><fa-icon [icon]="faChartSimple" class="w-5"></fa-icon> Search Peak Events</a></li>
                            }
                            @if(this.auth.has('raw_sequence_item_read')) {
                                <li><a routerLink="/raw-sequence-items" routerLinkActive="active"><fa-icon [icon]="faCheck" class="w-5"></fa-icon> Search Raw Sequence Items</a></li>
                            }
                            @if(this.auth.has('processed_sequence_item_read')) {
                                <li><a routerLink="/processed-sequence-items" routerLinkActive="active"><fa-icon [icon]="faCheckDouble" class="w-5"></fa-icon> Search Processed Sequence Items</a></li>
                            }
                        </ul>
                    </details>
                </li>
                -->
                @if(this.auth.has('event_read') || 
                    this.auth.has('algorithm_read') || 
                    this.auth.has('version_read') || 
                    this.auth.has('firmware_read') || 
                    this.auth.has('device_status_read') || 
                    this.auth.has('sensor_type_read') 
                    ) {
                    <li>
                        <details>
                            <summary>
                                <fa-icon [icon]="faCog" class="w-5"></fa-icon>
                                Settings
                            </summary>
                            <ul>
                                @if(this.auth.has('event_read')) {
                                    <li><a routerLink="/events" routerLinkActive="active"><fa-icon [icon]="faCircleExclamation" class="w-5"></fa-icon> Events</a></li>
                                }
                                @if(this.auth.has('algorithm_read')) {
                                    <li><a routerLink="/algorithms" routerLinkActive="active"><fa-icon [icon]="faSquarePollHorizontal" class="w-5"></fa-icon> Algorithms</a></li>
                                }
                                @if(this.auth.has('version_read')) {
                                    <li><a routerLink="/versions" routerLinkActive="active"><fa-icon [icon]="faCodeMerge" class="w-5"></fa-icon> Versions</a></li>
                                }
                                @if(this.auth.has('firmware_read')) {
                                    <li><a routerLink="/firmware" routerLinkActive="active"><fa-icon [icon]="faCodeFork" class="w-5"></fa-icon> Firmware</a></li>
                                }
                                @if(this.auth.has('device_status_read')) {
                                    <li><a routerLink="/device-statuses" routerLinkActive="active"><fa-icon [icon]="faLayerGroup" class="w-5"></fa-icon> Device Statuses</a></li>
                                }
                                @if(this.auth.has('sensor_type_read')) {
                                    <li><a routerLink="/sensor-types" routerLinkActive="active"><fa-icon [icon]="faSliders" class="w-5"></fa-icon> Sensor Types</a></li>
                                }
                            </ul>
                        </details>
                    </li>
                }
            </ul>
        </nav>
    </aside>
</div>
<app-toast #messageToast type="success" />