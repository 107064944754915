import { Component } from '@angular/core';
import { HeadMetaComponent } from '@app/components/ui.component';
import { AuthService } from '@app/services/auth.service';
import { Observable } from 'rxjs';
import { LetDirective } from '@ngrx/component';
import { LoadingErrorBlockComponent } from "@app/components/loading-error-block/loading-error-block.component";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss'
})
export class HomePage {
  constructor(
    public auth: AuthService,
  ) {
    
  }
}
