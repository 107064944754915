import { Component, signal } from '@angular/core';
import { PhoneFormComponent } from "@app/components/forms/phone/form.component";
import { PhoneHelperService } from '@app/services/helpers/phone-helper.service';
import { Router, RouterModule } from '@angular/router';
import { AppHttpError } from '@app/interfaces/pg-models.interface';
import { PhoneCreate } from '@app/models/phone.interface';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { HeadMetaComponent } from '@app/components/ui.component';
import { ToastService } from '@app/services/toast.service';

@Component({
  selector: 'app-phone-create',
  standalone: true,
  templateUrl: './create.page.html',
  styleUrl: './create.page.scss',
  imports: [
    HeadMetaComponent,
    PhoneFormComponent,
    RouterModule,
    FontAwesomeModule,
  ]
})
export class PhoneCreatePage {
  public title = 'New Phone';
  formValid = signal(false);
  formValue = signal<PhoneCreate | null>(null);

  faArrowLeft = faArrowLeft;

  constructor(
    private phoneService: PhoneHelperService,
    private router: Router,
    private toast: ToastService,
  ) {
    
  }

  create() {
    let val = this.formValue();
    if(val) {
      this.phoneService.create(val)
        .subscribe({
          next: id => {
            console.log(id)
            if(id) {
              this.router.navigateByUrl('/phones/' + id);
            }
          },
          error: (err: AppHttpError) => {
            console.log(err.error)
            this.toast.message({ message: err.error.code + ": " + err.error.message, type: 'error'});
          }
        });
    }
  }
}
