<head-meta [title]="this.title" />
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <ng-container *ngrxLet="webhook$ as webhook; error as e; complete as c">
                @if(webhook) {
                    <div class="navbar bg-base-100">
                        <div class="flex-none">
                          <a routerLink="/webhooks" routerLinkActive="active" class="btn btn-square btn-ghost text-primary"><fa-icon [icon]="faArrowLeft" class="w-5"></fa-icon></a>
                        </div>
                        <div class="flex-1">
                          <h1 class="h1 ml-4">Webhook Detail</h1>
                        </div>
                        <div class="flex-none">
                            @if(this.auth.has('webhook_update')) {
                                <button [routerLink]="'/webhooks/' + this.id + '/edit'" routerLinkActive="active" class="btn btn-ghost text-primary">
                                    <fa-icon [icon]="faPenToSquare" class="w-5"></fa-icon> Edit
                                </button>
                            }
                        </div>
                    </div>
                    <hr class="hr" />
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="border-t border-gray-100">
                            <dl class="divide-y divide-gray-100">
                                <div>
                                    <app-detail-display title="URL" [value]="webhook.url" />
                                </div>
                                <div>
                                    <app-detail-display title="Sign Secret" value="****************" />
                                </div>
                                <div>
                                    <app-detail-display title="Provider" [value]="webhook.provider.name" />
                                </div>
                                <div>
                                    <app-detail-display title="Send processed sequence items" [value]="webhook.processed_sequence_items ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Send peak events" [value]="webhook.peak_events ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Send tamper events" [value]="webhook.tamper_events? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Send sequence events" [value]="webhook.sequence_events? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Send sequence updates" [value]="webhook.sequences ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Send device updates" [value]="webhook.devices ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-created-updated-display [entity]="webhook" />
                                </div>
                            </dl>
                        </div>
                    </div>
                }
                <app-loading-error-block [error]="e?.error" [complete]="c" />
            </ng-container>
        </div>
    </div>
</article>