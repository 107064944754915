import { Component, input, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputFieldComponent } from '@app/components/input-field/input-field.component';
import { AutoForm } from '@app/interfaces/auto-form.interface';
import { AlgorithmCreate } from '@app/models/algorithm.interface';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-algorithm-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputFieldComponent,
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class AlgorithmFormComponent {
  initialValue = input<AlgorithmCreate>();
  formChange = output<AlgorithmCreate>();
  formValid = output<boolean>();

  algorithmForm = new FormGroup<AutoForm<AlgorithmCreate>>({
    name: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    internal_id: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    plot_frequency: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    use_fahrenheit: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
    graph_tac: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
    graph_environment: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
    graph_baseline: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
    graph_bio: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
    graph_ambient: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
    graph_tamper: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
    graph_battery: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
    min_peak_height: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    min_peak_prominence: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    min_peak_hour_height: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    min_peak_half_hour_height: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    max_peak_rise_slope: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    max_peak_fall_slope: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    exclude_dqd_alcohol_events: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
    discard_initial_data_hours: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    max_time_without_reading: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    percentile_filter_window: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    flatten_tac: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
    rh_slope_1: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    rh_slope_2: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    gain_resistance: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    correction_factor_5: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    neg_values_to_zero: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
    relative_humidity_synth_threshold: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    temp_correction_date_chunk_size: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    temp_correction_rh_range_size: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    points_of_window: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    smooth_factor: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    threshold: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    tac_threshold: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    percentile_filtering: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
    fft_smoothing: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
    rh_correction: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
    temp_correction: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
    baseline_correction: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
    delta_threshold: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    distance_between_peaks: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    savgol_filter_window: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    savgol_filter_polynomial: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    factor_b1: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    factor_a1: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    alpha: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
  });

  constructor() {
    toObservable(this.initialValue).subscribe((initial) => {
      if(initial) {
        Object.keys(initial).forEach(key => {
          // https://stackoverflow.com/a/62438434/3341745
          let prop = key as keyof typeof initial;
          if(initial[prop] != null) {
            this.algorithmForm.get(key)?.setValue(initial[prop]);
          }
        });
      }
      this.onChange();
    });
  }

  onChange() {
    this.formValid.emit(this.algorithmForm.valid);
    this.formChange.emit(<AlgorithmCreate>this.algorithmForm.value);
  }
}