import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingErrorBlockComponent } from '@app/components/loading-error-block/loading-error-block.component';
import { HeadMetaComponent } from '@app/components/ui.component';
import { Phone } from '@app/models/phone.interface';
import { AuthService } from '@app/services/auth.service';
import { PhoneHelperService } from '@app/services/helpers/phone-helper.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { 
  faCirclePlus,
  faArrowLeft 
 } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-phones',
  standalone: true,
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent,
    DatePipe,
    FontAwesomeModule,
    RouterModule,
  ],
  templateUrl: './listing.page.html',
  styleUrl: './listing.page.scss'
})
export class PhonesPage {
  public title = 'Phones';
  phones$: Observable<Phone[]>;

  faCirclePlus = faCirclePlus;
  faArrowLeft = faArrowLeft;

  constructor(
    private phoneHelper: PhoneHelperService,
    public auth: AuthService,
  ) {
    this.phones$ = this.phoneHelper.getAll();
  }
}
