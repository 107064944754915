<head-meta [title]="this.title" />
<article>
    <ng-container *ngrxLet="user$ as user; error as e; complete as c">
        <app-loading-error-block [error]="e?.error" [complete]="c" />
        <div class="card w-full bg-base-100 shadow-xl mb-4">
            <div class="card-body">
                <div class="navbar bg-base-100">
                    <div class="flex-none">
                        <a routerLink="/users/{{this.id}}" routerLinkActive="active" class="btn btn-square btn-ghost text-primary"><fa-icon [icon]="faArrowLeft" class="w-5"></fa-icon></a>
                    </div>
                    <div class="flex-1">
                        <h1 class="h1 ml-4">Edit User</h1>
                    </div>
                </div>
                <hr class="hr" />
                <app-user-form 
                    (formValid)="formValid.set($event)"
                    (formChange)="formValue.set($event)"
                    [initialValue]="user"
                />
                <button (click)="update()" class="btn btn-primary" [disabled]="!formValid()"><fa-icon [icon]="faSave" class="w-5"></fa-icon> Save</button>

                @if(isMyUser(user.id)) {
                    <hr class="hr-accent mt-8" />
                    <div class="navbar bg-base-100">
                        <div class="flex-1">
                            <h2 class="h2">Change Password</h2>
                        </div>
                    </div>
                    <app-password-reset-form 
                        (formValid)="passwordFormValid.set($event)"
                        (formChange)="passwordFormValue.set($event)"
                    />
                    <button (click)="updatePassword()" class="btn btn-primary" [disabled]="!passwordFormValid()"><fa-icon [icon]="faSave" class="w-5"></fa-icon> Update Password</button>
                } @else if(this.auth.has('role_manage')){
                    <hr class="hr-accent mt-8" />
                    <div class="navbar bg-base-100">
                        <div class="flex-1">
                            <h2 class="h2">Update Roles</h2>
                        </div>
                    </div>
                    <ng-container *ngrxLet="roles$ as roles; error as e; complete as c">
                        <app-user-role-form
                        (formValid)="userRoleFormValid.set($event)"
                        (formChange)="userRoleFormValue.set($event)"
                        [initialValue]="roles"
                        />
                    </ng-container>
                    
                    <button (click)="updateRole()" class="btn btn-primary" [disabled]="!userRoleFormValid()"><fa-icon [icon]="faSave" class="w-5"></fa-icon> Update Roles</button>
                }
            </div>
        </div>
    </ng-container>
    
</article>