<head-meta [title]="this.title"></head-meta>
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <div class="navbar bg-base-100">
                <div class="flex-none">
                </div>
                <div class="flex-1">
                  <h1 class="h1">{{this.title}}</h1>
                  <form [formGroup]="this.searchForm">
                    <div class="form-control">
                        <div class="ml-4 input-group flex items-center gap-2 w-full max-w-md">
                            <select class="input input-bordered input-md " formControlName="date_range" name="date_range">
                                <option [value]="0">All</option>
                                <option [value]="1">Past 24 Hours</option>
                                <option [value]="2">Past 2 Days</option>
                                <option [value]="3">Past 7 Days</option>
                                <option [value]="4">Past 30 Days</option>
                                <option [value]="5">Custom</option>
                            </select>
                            @if(this.searchForm.get('date_range')?.value == 5){
                                <app-date-range
                                    (formValid)="formValid.set($event)"
                                    (formChange)="formValue.set($event)"
                                    [initialValue]="dateRanges"
                                />
                            }
                            <ng-container *ngrxLet="providers$ as providers; error as e; complete as c">
                                @if(c) {
                                    @if(providers.length > 0) {
                                        <select class="input input-bordered input-md hidden w-40 xl:flex" formControlName="provider_id" name="provider_id">
                                            <option value="0">Any Provider</option>
                                            @for (provider of providers; track $index) {
                                                <option [value]="provider.id">{{ provider.name }}</option>
                                            }
                                        </select>
                                    }
                                }
                            </ng-container>
                            <input formControlName="sequence_id" name="sequence_id" type="text" placeholder="ID" class="input input-bordered input-md w-32 hidden xl:flex" />
                            <button class="btn btn-square btn-md" [disabled]="!this.searchForm.valid" (click)="this.submit()">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                  </form>
                </div>
                <div class="flex-none">
                    <!--
                    @if(this.auth.has('sequence_create')) {
                        <button routerLink="/sequences/create" routerLinkActive="active" class="btn btn-ghost text-primary"><fa-icon [icon]="faCirclePlus" class="w-5"></fa-icon> Add</button>
                    }
                    -->
                </div>
            </div>
            <hr class="hr" />
            <table class="table table-zebra table-pin-rows">
                <ng-container *ngrxLet="sequences$ as sequences; error as e; complete as c">
                    <app-loading-error-block [error]="e?.error" [complete]="c" />
                    @if(c) {
                        <thead>
                            <th>ID</th>
                            <th>Device</th>
                            <th>Provider</th>
                            <th>Started at</th>
                            <th>Last activity at</th>
                            <th>End at</th>
                        </thead>
                        <tbody>
                            @if(sequences.length > 0) {
                                @for (sequence of sequences; track $index) {
                                    <tr [routerLink]="'/sequences/' + sequence.id" class="cursor-pointer">
                                        <td>{{ sequence.id }}</td>
                                        <td>{{ sequence.device.serial_number }}</td>
                                        <td>{{ sequence.provider.name }}</td>
                                        <td>{{ sequence.started_at | date }}</td>
                                        <td>{{ sequence.last_activity_at | date }}</td>
                                        <td>{{ sequence.ended_at | date }}</td>
                                    </tr>
                                }
                            } @else {
                                <tr>
                                    <td class="text-center" colspan="6">
                                        <span class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
                                            <span class="mt-2 block text-sm text-gray-400">No Sequences</span>
                                        </span>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    }
                </ng-container>
            </table>
        </div>
    </div>
</article>