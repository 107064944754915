import { Component, input, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputFieldComponent } from '@app/components/input-field/input-field.component';
import { AutoForm } from '@app/interfaces/auto-form.interface';
import { SequenceCreate } from '@app/models/sequence.interface';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-sequence-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputFieldComponent,
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class SequenceFormComponent {
  initialValue = input<SequenceCreate>();
  formChange = output<SequenceCreate>();
  formValid = output<boolean>();

  sequenceForm = new FormGroup<AutoForm<SequenceCreate>>({
    device_id: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    provider_id: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    is_processing_queued: new FormControl<boolean>(false, { nonNullable: true}),
    //started_at: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
    //last_activity_at: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
    ended_at: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
  });

  constructor() {
    toObservable(this.initialValue).subscribe((initial) => {
      if(initial) {
        Object.keys(initial).forEach(key => {
          // https://stackoverflow.com/a/62438434/3341745
          let prop = key as keyof typeof initial;
          if(initial[prop] != null) {
            this.sequenceForm.get(key)?.setValue(initial[prop]);
          }
        });
      }
      this.onChange();
    });
  }

  onChange() {
    this.formValid.emit(this.sequenceForm.valid);
    this.formChange.emit(<SequenceCreate>this.sequenceForm.value);
  }
}