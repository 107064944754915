

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="algorithmForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="name"
                        [form]="algorithmForm"
                        label="Name"
                        [errorMessages]="{ required: 'Name is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="internal_id"
                        [form]="algorithmForm"
                        type="number"
                        label="Internal Id"
                        [errorMessages]="{ required: 'Internal Id is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="plot_frequency"
                        [form]="algorithmForm"
                        type="number"
                        step="5"
                        label="Plot Frequency"
                        [errorMessages]="{ required: 'Plot Frequency is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="use_fahrenheit"
                        type="toggle"
                        [form]="algorithmForm"
                        label="Use Fahrenheit?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="graph_tac"
                        type="toggle"
                        [form]="algorithmForm"
                        label="Graph TAC?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="graph_environment"
                        type="toggle"
                        [form]="algorithmForm"
                        label="Graph Environment?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="graph_baseline"
                        type="toggle"
                        [form]="algorithmForm"
                        label="Graph Baseline?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="graph_bio"
                        type="toggle"
                        [form]="algorithmForm"
                        label="Graph Bio?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="graph_ambient"
                        type="toggle"
                        [form]="algorithmForm"
                        label="Graph Ambient?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="graph_tamper"
                        type="toggle"
                        [form]="algorithmForm"
                        label="Graph Tamper?"
                    />
                </div>  
                <div>
                    <app-input-field
                        controlName="graph_battery"
                        type="toggle"
                        [form]="algorithmForm"
                        label="Graph Battery?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="min_peak_height"
                        [form]="algorithmForm"
                        type="number"
                        step="0.005"
                        label="Min Peak Height"
                        [errorMessages]="{ required: 'Min Peak Height is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="min_peak_prominence"
                        [form]="algorithmForm"
                        type="number"
                        step="0.005"
                        label="Min Peak Prominence"
                        [errorMessages]="{ required: 'Min Peak Prominence is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="min_peak_hour_height"
                        [form]="algorithmForm"
                        type="number"
                        step="0.005"
                        label="Min Peak Hour Height"
                        [errorMessages]="{ required: 'Min Peak Hour Height is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="min_peak_half_hour_height"
                        [form]="algorithmForm"
                        type="number"
                        step="0.005"
                        label="Min Peak Half Hour Height"
                        [errorMessages]="{ required: 'Min Peak Half Hour Height is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="max_peak_rise_slope"
                        [form]="algorithmForm"
                        type="number"
                        step="0.005"
                        label="Max Peak Rise Slope"
                        [errorMessages]="{ required: 'Max Peak Rise Slope is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="max_peak_fall_slope"
                        [form]="algorithmForm"
                        type="number"
                        step="0.005"
                        label="Max Peak Fall Slope"
                        [errorMessages]="{ required: 'Max Peak Fall Slope is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="exclude_dqd_alcohol_events"
                        type="toggle"
                        [form]="algorithmForm"
                        label="Exclude DQD Alcohol Events?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="discard_initial_data_hours"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Discard Initial Data Hours"
                        [errorMessages]="{ required: 'Discard Initial Data Hours is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="max_time_without_reading"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Max Time Without Reading"
                        [errorMessages]="{ required: 'Max Time Without Reading is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="percentile_filter_window"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Percentile Filter Window"
                        [errorMessages]="{ required: 'Percentile Filter Window is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="flatten_tac"
                        type="toggle"
                        [form]="algorithmForm"
                        label="Flatten TAC?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="rh_slope_1"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="RH Slope 1"
                        [errorMessages]="{ required: 'RH Slope 1 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="rh_slope_2"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="RH Slope 2"
                        [errorMessages]="{ required: 'RH Slope 2 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="gain_resistance"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Gain Resistance"
                        [errorMessages]="{ required: 'Gain Resistance is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="correction_factor_5"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Correction Factor 5"
                        [errorMessages]="{ required: 'Correction Factor 5 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="neg_values_to_zero"
                        type="toggle"
                        [form]="algorithmForm"
                        label="Neg Values to Zero?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="relative_humidity_synth_threshold"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Relative Humidity Synth Threshold"
                        [errorMessages]="{ required: 'Relative Humidity Synth Threshold is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="temp_correction_date_chunk_size"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Temp Correction Date Chunk Size"
                        [errorMessages]="{ required: 'Temp Correction Date Chunk Size is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="temp_correction_rh_range_size"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Temp Correction RH Range Size"
                        [errorMessages]="{ required: 'Temp Correction RH Range Size is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="points_of_window"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Points of Window"
                        [errorMessages]="{ required: 'Points of Window is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="smooth_factor"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Smooth Factor"
                        [errorMessages]="{ required: 'Smooth Factor is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="threshold"
                        [form]="algorithmForm"
                        type="number"
                        step="0.005"
                        label="Threshold"
                        [errorMessages]="{ required: 'Threshold is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="tac_threshold"
                        [form]="algorithmForm"
                        type="number"
                        step="0.0005"
                        label="TAC Threshold"
                        [errorMessages]="{ required: 'TAC Threshold is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="percentile_filtering"
                        type="toggle"
                        [form]="algorithmForm"
                        label="Percentile Filtering?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="fft_smoothing"
                        type="toggle"
                        [form]="algorithmForm"
                        label="FFT Smoothing?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="rh_correction"
                        type="toggle"
                        [form]="algorithmForm"
                        label="RH Correction?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="temp_correction"
                        type="toggle"
                        [form]="algorithmForm"
                        label="Temp Correction?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="delta_threshold"
                        [form]="algorithmForm"
                        type="number"
                        step="0.05"
                        label="Delta Threshold"
                        [errorMessages]="{ required: 'Delta Threshold is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="distance_between_peaks"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Distance Between Peaks"
                        [errorMessages]="{ required: 'Distance Between Peaks is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="savgol_filter_window"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Savgol Filter Window"
                        [errorMessages]="{ required: 'Savgol Filter Window is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="savgol_filter_polynomial"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Savgol Filter Polynomial"
                        [errorMessages]="{ required: 'Savgol Filter Polynomial is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="factor_b1"
                        [form]="algorithmForm"
                        type="number"
                        step="0.00001"
                        label="Factor B1"
                        [errorMessages]="{ required: 'Factor B1 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="factor_a1"
                        [form]="algorithmForm"
                        type="number"
                        step="0.00001"
                        label="Factor A1"
                        [errorMessages]="{ required: 'Factor A1 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="alpha"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Alpha"
                        [errorMessages]="{ required: 'Alpha is required'}"
                    />
                </div>
            </div>
        </form>
    </div>
</div>