

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="eventForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="name"
                        [form]="eventForm"
                        label="Event Name"
                        [errorMessages]="{ required: 'Event name is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="internal_id"
                        [form]="eventForm"
                        label="Internal Id"
                        [errorMessages]="{ required: 'Internal Id is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="description"
                        type="textarea"
                        [form]="eventForm"
                        label="Description"
                        [errorMessages]="{ required: 'Description is required'}"
                    />
                </div>
            </div>
        </form>
    </div>
</div>