import { DatePipe } from '@angular/common';
import { Component, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoadingErrorBlockComponent } from '@app/components/loading-error-block/loading-error-block.component';
import { HeadMetaComponent } from '@app/components/ui.component';
import { DeviceStatus } from '@app/models/device-status.interface';
import { Device } from '@app/models/device.interface';
import { Provider } from '@app/models/provider.interface';
import { Sequence } from '@app/models/sequence.interface';
import { AuthService } from '@app/services/auth.service';
import { DeviceHelperService } from '@app/services/helpers/device-helper.service';
import { DeviceStatusHelperService } from '@app/services/helpers/device-status-helper.service';
import { ProviderHelperService } from '@app/services/helpers/provider-helper.service';
import { SequenceHelperService } from '@app/services/helpers/sequence-helper.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { 
  faCirclePlus,
  faArrowLeft 
 } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';
import { DateRangeComponent } from '@app/components/date-range/date-range.component';
import { DateRange } from '@app/interfaces/date-range.interface';

@Component({
  selector: 'app-sequences',
  standalone: true,
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent,
    DatePipe,
    FontAwesomeModule,
    RouterModule,
    ReactiveFormsModule,
    DateRangeComponent,
  ],
  templateUrl: './listing.page.html',
  styleUrl: './listing.page.scss'
})
export class SequencesPage {
  public title = 'Sequences';
  sequences$: Observable<Sequence[]>;
  devices$: Observable<Device[]>;
  providers$: Observable<Provider[]>;

  searchForm: FormGroup = new FormGroup({
    date_range: new FormControl<number>(4),
    sequence_id: new FormControl<string>(''),
    provider_id: new FormControl<number>(0),
    start: new FormControl<Date>(new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000))),
    end: new FormControl<Date>(new Date()),
  });
  dateRanges: DateRange = {
    start: new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)),
    end: new Date,
  };
  formValid = signal(false);
  formValue = signal<DateRange | null>(null);

  faCirclePlus = faCirclePlus;
  faArrowLeft = faArrowLeft;

  constructor(
    private sequenceHelper: SequenceHelperService,
    private deviceHelper: DeviceHelperService,
    private providerHelper: ProviderHelperService,
    public auth: AuthService,
  ) {
    this.sequences$ = this.sequenceHelper.search(null, null, new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000)), new Date);
    this.devices$ = this.deviceHelper.getAll();
    this.providers$ = this.providerHelper.getAll();
  }

  public submit(){
    const form = this.searchForm.value;
    if(form) {
      switch(parseInt(form.date_range)){
        case 1: // Past 24 hours
          form.start = new Date(new Date().getTime() - (1 * 24 * 60 * 60 * 1000));
          form.end = new Date();
        break;
        case 2: // Past 2 Days
          form.start = new Date(new Date().getTime() - (2 * 24 * 60 * 60 * 1000));
          form.end = new Date();
        break;
        case 3: // Past 7 Days
          form.start = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
          form.end = new Date();
        break;
        case 4: // Past 30 Days
          form.start = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
          form.end = new Date();
        break;
        case 5:
          // values set by user
          let val = this.formValue();
          if(val){
            form.start = val.start;
            form.end = val.end;
          }
        break;
        case 0: // All
        default:
          form.start = null;
          form.end = null;
        break;
      }
      this.sequences$ = this.sequenceHelper.search(parseInt(form.sequence_id), form.provider_id, form.start, form.end);
    }
  }
}
