import { Component, input, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputFieldComponent } from '@app/components/input-field/input-field.component';
import { AutoForm } from '@app/interfaces/auto-form.interface';
import { SensorTypeCreate } from '@app/models/sensor-type.interface';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-sensor-type-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputFieldComponent,
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class SensorTypeFormComponent {
  initialValue = input<SensorTypeCreate>();
  formChange = output<SensorTypeCreate>();
  formValid = output<boolean>();

  sensorTypeForm = new FormGroup<AutoForm<SensorTypeCreate>>({
    name: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
    internal_id: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
  });

  constructor() {
    toObservable(this.initialValue).subscribe((initial) => {
      if(initial) {
        Object.keys(initial).forEach(key => {
          // https://stackoverflow.com/a/62438434/3341745
          let prop = key as keyof typeof initial;
          if(initial[prop] != null) {
            this.sensorTypeForm.get(key)?.setValue(initial[prop]);
          }
        });
      }
      this.onChange();
    });
  }

  onChange() {
    this.formValid.emit(this.sensorTypeForm.valid);
    this.formChange.emit(<SensorTypeCreate>this.sensorTypeForm.value);
  }
}